<div class="container" *ngIf="submited">
<kt-api-loader [isLoadingResults]="submited"></kt-api-loader>
</div>
<section class="container" *ngIf="!submited" id="contact-us-container">
    
    <div class="row justify-content-center mt-5 mr-0 ml-0 mr-top-5">
        <h3 class="poppins fw-bold form-title mb-5 text-center">{{'ContactUs' | translate}}</h3>
    </div>
   
   <div class="row d-flex justify-content-center">
       
       <div class="form-wrapper col-sm-12 col-lg-6 col-xl-6 col-md-6">
           <form [formGroup]="contactForm">
               <div class="form-group">
                   <input type="text" class="form-control shadow-sm rounded" formControlName="name"
                       [placeholder]="'name' | translate" required
                       [ngClass]="contactForm.controls.name.errors && contactForm.controls.name.touched ? 'is-invalid' : ''">
                       <div class="invalid-feedback">
                        Name is required
                      </div>
                   
               </div>
               <div class="form-group">
                   <input type="email" class="form-control shadow-sm rounded" formControlName="email"
                       [placeholder]="'email' | translate" required
                       [ngClass]="contactForm.controls.email.errors && contactForm.controls.email.touched ? 'is-invalid' : ''">
                       <div class="invalid-feedback">
                        Email is required
                      </div>
                   
               </div>
               <div class="form-group">
                   <input type="text" class="form-control shadow-sm rounded" formControlName="phone"
                       [placeholder]="'phone' | translate" required
                       [ngClass]="contactForm.controls.phone.errors && contactForm.controls.phone.touched ? 'is-invalid' : ''">
                       <div class="invalid-feedback">
                        Phone is required
                      </div>
                   
               </div>
               <div class="form-group mb-5">
                   <textarea type="text" formControlName="body" class="form-control shadow-sm rounded"
                       [placeholder]="'message' | translate" rows="10" required
                       [ngClass]="contactForm.controls.body.errors && contactForm.controls.body.touched ? 'is-invalid' : ''"></textarea>
                       <div class="invalid-feedback">
                        Message is required
                      </div>
               </div>
               <div class="form-group">
                   <div class="row justify-content-center">
                       <div class="col-md-6 col-12">
                           <button class="btn btn-primary contact-button" type="button" [disabled]="submited"
                               (click)="submit()">{{'send' | translate}}</button>
                       </div>
                   </div>
                   
               </div>
           </form>
       </div>
   </div>
</section>  
