<div class="advantages" id="Features">
  <div class="container">
    <div class="row no-gutters ">
      <div class="col-md-12">
        
        <h4 class="title text-center mb-5">
          {{'AdvantagesofBeinhealthprogram' | translate}}
        </h4>
      </div>
    </div>
    
    <div class="row no-gutters">
      <div class="col-md-12">
        
        <!-- <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']">
          <ng-template carouselSlide>
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-1.png">
              <p>{{'Flexible' | translate}}</p>
            </div>
          </div>
          </ng-template>
          <ng-template carouselSlide>
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-2.png">
              <p>{{'Funding' | translate}}</p>
            </div>
          </div>
          </ng-template>
          <ng-template carouselSlide>
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-3.png">
              <p>{{'Thepatient' | translate}}</p>
            </div>
          </div>
          </ng-template>
          <ng-template carouselSlide>
          <div class="item">
            <div class="card">
              <img src="assets/icons/icon-4.png">
              <p>{{'Aspecialized' | translate}}</p>
            </div>
          </div>
          </ng-template>
          <ng-template carouselSlide>
    
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-5.png">
              <p>{{'Instant' | translate}}</p>
            </div>
          </div>
          </ng-template>
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-6.png">
              <p>{{'Differentpaymentoptions' | translate}}</p>
            </div>
          </div>
          <div class="item">
            <div class="card">
              <img src="assets/icons/Icon-7.png">
              <p>{{'Possibilityofcollecting' | translate}}</p>
            </div>
          </div>
    
        </owl-carousel> -->
      </div>
    </div>

    <!-- <div class="row no-gutters">
      <div class="col-md-12">
        <a routerLink="/individuals-privacy"
          class="title text-center mt-5 d-flex align-items-center justify-content-center">
          <small>{{'TermsAndConditionsApply' | translate}}</small>
        </a>
      </div>
    </div> -->

  
  </div>
  
</div>
<div class="con">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-1.png">
          <p>{{'Flexible' | translate}}</p>
      </div>
      </div>
    </ng-template>  
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-2.png">
          <p>{{'Funding' | translate}}</p>
        </div>
      </div>
    </ng-template>  
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-3.png">
          <p>{{'Thepatient' | translate}}</p>
        </div>
      </div>
    </ng-template>  
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-5.png">
          <p>{{'Instant' | translate}}</p>
        </div>
      </div>
    </ng-template>  
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-6.png">
          <p>{{'Differentpaymentoptions' | translate}}</p>
        </div>
      </div>
    </ng-template>  
    <ng-template carouselSlide>
      <div class="item">
        <div class="card">
          <img src="assets/icons/Icon-7.png">
          <p>{{'Possibilityofcollecting' | translate}}</p>
        </div>
      </div>
    </ng-template>  
  </owl-carousel-o>
</div>

