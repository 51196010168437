<div class="wrapper py-5 mt-md-0 mt-4 text-right" dir="rtl">

  <div class="container">
    <h1 class="text-center mb-4">
      {{'TERMSANDCONDITIONS.pageTitle'|translate}}
    </h1>

    <p class="main_p" style="font-size: 1rem">
      {{'TERMSANDCONDITIONS.sayingHello'|translate}}
    </p>
    <h4>
      {{'TERMSANDCONDITIONS.title'|translate}}
    </h4>

    <p class="main_p" style="font-size: 1rem">
      {{'TERMSANDCONDITIONS.description'|translate}}
    </p>

    <span style="font-size: 1rem">
      {{'TERMSANDCONDITIONS.subDescription'|translate}}
    </span>
    <ul>
      <li>
        {{'TERMSANDCONDITIONS.LIST.item1'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item2'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item3'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item4'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item5'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item6'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item7'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item8'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item9'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item10'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item11'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item12.title'|translate}}
      </li>

      <ul class="sub-tree">
        <li>{{'TERMSANDCONDITIONS.LIST.item12.subItems.item1'|translate}}</li>
        <li>
          {{'TERMSANDCONDITIONS.LIST.item12.subItems.item2'|translate}}
        </li>
        <li>
          {{'TERMSANDCONDITIONS.LIST.item12.subItems.item3'|translate}}
        </li>
        <li>
          {{'TERMSANDCONDITIONS.LIST.item12.subItems.item4'|translate}}
        </li>

        <li>
          {{'TERMSANDCONDITIONS.LIST.item12.subItems.item5'|translate}}
        </li>

      </ul>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item13'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item14'|translate}}
      </li>

      <li>
        {{'TERMSANDCONDITIONS.LIST.item15'|translate}}
      </li>
    </ul>
  </div>
</div>
