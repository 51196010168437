<div class="howItWork" id="howItWork">
  <div class="container">
    <div class="row">
      <mat-accordion class="w-100">
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
            <h2> {{'Howitworks' | translate }}</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div class="content">
              
              <li>
                <!-- <img src="assets/icons/true.png"> {{'RegistertoBeinHealth' | translate }} -->
                <h5>{{'title1' | translate }}</h5>
                <p>{{'para1' | translate }}</p>
              </li>
              <li>
                <h5>{{'title2' | translate }}</h5>
                <p>{{'para2' | translate }}</p>
                <!-- <img src="assets/icons/true.png"> {{'Browsetheavailablehospitalsanddoctors' | translate }} -->
              </li>
              <li>
                <h5>{{'title3' | translate }}</h5>
                <p>{{'para3' | translate }}</p>
                <!-- <img src="assets/icons/true.png">{{'checktotalvalue' | translate}} -->
              </li>
              <li>
                <h5>{{'title4' | translate }}</h5>
                <p>{{'para4' | translate }}</p>
                <!-- <img src="assets/icons/true.png"> {{'payWithYourWallet' | translate }} -->
              </li>
              <!-- <li><img src="assets/icons/true.png"> {{'serviceprovider' | translate }}
                      </li> -->
            
              <!-- <div class="">
                        <button class="btn btn-info">
                          <a href="https://apps.apple.com/eg/app/be-in-health-patients/id1551943673" target="_blank" style="color:white">
                            <div class="row no-gutters">
                              <div class="col-ms-4">
                                <i class="fab fa-apple"></i>
                              </div>
                              <div class="col-ms-8">
                                {{'GetItOn' | translate}} <br> {{'appleApp' | translate}}
                              </div>
                            </div>
                          </a>
                        </button>
                        <button class="btn btn-info">
                          <a href="https://play.google.com/store/apps/details?id=com.beinhealth.patient" target="_blank" style="color:white">
                            <div class="row no-gutters">
                              <div class="col-ms-4">
                                <i class="fab fa-google-play"></i>
                              </div>
                              <div class="col-ms-8">
                                {{'GetItOn' | translate}} <br> {{'androidApp' | translate}}
                              </div>
                            </div>
                          </a>
                        </button>
                      </div> -->
            </div>
        </mat-expansion-panel>
      </mat-accordion>
 
      <!-- <div class="col-md-6 images-layers">
        <img src="assets/images/Mobile-layer-1.png" class="layerOne">
        <img src="assets/images/Mobile-layer-2.png" class="layerTow">

      </div> -->
    </div>
  </div>
</div>
