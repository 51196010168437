<div class="surgeries bg-color">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-md-12">
        <div class="title text-center">
          <h6>{{'TypesofSurgeries' | translate}}</h6>
          <p>{{'BeInhealthCovers' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12">
        <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding']">
          <div class="item">
            <img src="assets/slider/slider-1.png">
            <p>{{'Orthopedicsandprosthetic' | translate}}</p>
          </div>
          <div class="item">
            <img src="assets/slider/slider-2.png">
            <p>{{'Cosmeticsurgeries' | translate}}</p>

          </div>
          <div class="item">
            <img src="assets/slider/slider-3.png">
            <p>{{'Obesitysurgeries' | translate}}</p>

          </div>
          <div class="item">
            <img src="assets/slider/slider-4.png">
            <p>{{'Eyeoperationsandvisioncorrection' | translate}}</p>

          </div>
          <div class="item">
            <img src="assets/slider/slider-5.png">
            <p>{{'Dentalsurgeriesandorthodontic' | translate}}</p>

          </div>
          <div class="item">
            <img src="assets/slider/slider-6.png">
            <p>{{'Generalsurgeries' | translate}}</p>

          </div>
          <div class="item">
            <img src="assets/slider/slider-7.png">
            <p>{{'Caesareanandvaginaldelivery' | translate}}</p>

          </div>
        </owl-carousel>
      </div>
    </div>
  </div>

</div>
