<div class="wrapper py-5 mt-md-0 mt-4 text-right">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title">
          <h2 class="text-center mb-4">الشروط و الاحكام( الاطباء)</h2>
        </div>
      </div>
      <div class="col-md-12 content text-right">
        <p>
          السادة مقدمي الخدمات الطبية اهلا بك على تطبيق بي ان هيلث
          للاستفادة من مزايا التسويق الالكتروني و نظم التقسيط المتاحة و المسوقة على منصات و مواقع بي ان هيلث الالكترونية
        </p>
        <ul>
          <li> باستخدام هذا التطبيق يقر المستخدم بانه يعمل في مجال الخدمات الطبية وان جميع المستندات المقدمة من طرفه و
            صلاحيته للعمل بالمجال الطبي سليمة و متفقة مع اشتراطات ممارسة المهنة من الناحية الفنية و القانونية دون ادنى
            مسئولية على الطرف الاول.و يلتزم برفعها على المنصات و المواقع الاكترونية المملوكة للطرف الاول و تقديم صورة
            ضوئية معتمده للطرف الاول حالة طلب ذلك .</li>
          <li> يقر المستخدم باتباع جميع المعايير الطبية و الفنية حال تقديم الخدمة الطبية لمرضاه دون ادنى مسئولية على
            الطرف الاول، ويتحمل الطرف الثاني وحده كافة المخاطر الناتجه عن الإجراء الطبي المتبع من جانبه تجاه المستفيدين
            من الخدمات الطبيه المقدمه.</li>
          <li> يقر المستخدم بتحمله وحده كافة التعويضات عن الأخطاء الطبية الناتجه عن الإجراءت المتبعه من جانبه تجاه
            العملاء المستفيدين من الخدمه التي يقدمها الطرف الأول ، حيث أن العلاقه بين الطرفين ليست علاقة تابع بمتبوع أو
            علاقه رئيس بمرؤس وإنما هى علاقة تمويل فقط تهدف لإيجاد طريقه مناسبة لتمويل الإجراءات الطبية المطلوبه تجاه
            العملاء المستفيدن من الخدمات التي يقدمها الطرفين مع أخذ نسبه عملة مناسبه نتيجه هذا التمويل.</li>
          <li>يستحق نسبة خصم لصالح الطرف الاول بقيمة 10% من القيمة الاجمالية للخدمة الطبية المقدمة لمرضاه من خلال
            التطبيق </li>
          <li>يتعهد المستخدم فى حالة الغاء الاجراء الطبي لأى سبب برد قيمة الدفعة المقدمة من خلال الطرف الاول و ذلك فى
            موعد اقصاه اسبوع من تاريخ الالغاء</li>

          <li>يقر المستخدم بموافقته على دفع مصروفات تجديد اما سنوية او نصف سنوية او ربع سنوية وفقا لشروط التطبيق المعلنة
            و التي يحق لبي ان هيلث تغيرها كما يتلاأى لها دون الرجوع الى المستخدم للاستفادة من الخدمات المقدمة من قبل
            الطرف الاول و تجدد تلقائيا ما لم يتم الابلاغ الكتابي بعدم الرغبة في التجديد</li>

        </ul>
      </div>
    </div>
  </div>
</div>
