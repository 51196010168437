import { PrivacyPolicyIndividualsComponent } from './pages/privacy-policy/privacy-policy-individuals/privacy-policy-individuals.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PrivacyPolicyDoctorsComponent } from './pages/privacy-policy/privacy-policy-doctors/privacy-policy-doctors.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'individuals-privacy', component: PrivacyPolicyIndividualsComponent },
  { path: 'doctors-privacy', component: PrivacyPolicyDoctorsComponent },
  { path: 'contact-us', component: ContactUsComponent }
];

@NgModule({

  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
