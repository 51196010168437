import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BeinHealth';
  private googleKey = 'G-L6Q7GW1NBM';
  constructor(private translate: TranslateService,
    public router: Router,) {

    if (localStorage.getItem('lang') === 'ar') {
      const ar = require(`../assets/i18n/ar.json`);
      translate.setTranslation('ar', ar);
      translate.setDefaultLang('ar');
      this.setStyle('ar');
    } else {
      const en = require(`../assets/i18n/en.json`);
      translate.setTranslation('en', en);
      translate.setDefaultLang('en');
      this.setStyle('en');
    }


    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.googleKey,
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  setStyle(lang) {
    localStorage.setItem('lang', lang);
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    document.getElementsByTagName('body')[0].setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr');
    document.getElementsByTagName('body')[0].setAttribute('class', lang === 'ar' ? 'rtl' : 'ltr');
    document.body.setAttribute('style', lang === 'ar' ? 'text-align: right' : 'text-align: left')

  }

  ngOnInit(): void {

  }

}
