<div class="footer mt-5" id="footer">
  <div class="container">
    <hr>
    <div class="row flex justify-content-between padding-bottom">
      <div>
        <div class="info">
          <img src='../../../../assets/images/direction.png' />
          <p>{{'footer.Address'|translate}}
            <span>
              {{'footer.Addressinfo'|translate}}
            </span>
          </p>
        </div>
        <div class="info">
          <img src='../../../../assets/images/phone-call.png' />
          <p> {{'footer.tel'|translate}} <a href="tel:15793">15793</a> </p>
        </div>
      </div>
      <div>
        <div class="info">
          <img src='../../../../assets/images/email.png' />
          <p> {{'footer.email'|translate}} <a href="mailto:info@be-inhealth.com">info@be-inhealth.com</a></p>

        </div>
        <div class="info">
          <img src='../../../../assets/images/facebook.png' />
          <p> {{'footer.Facebook'|translate}} <a href="https://www.facebook.com/binhealthEgypt"
              target="_blank">https://www.facebook.com/binhealthEgypt</a></p>

        </div>
      </div>
    </div>
    <!-- <div class="row bg-color pb-2">
        <div class="col-md-4">
         <p>{{'PaythroughSouhoola' | translate}}</p> 
          <span class="label">{{'CONTACTUS.label1'|translate}} </span>
          <span class="value">
            01011442277
          </span>
        </div>

        <div class="col-md-4">
          <span class="label">{{'CONTACTUS.label3'|translate}} </span>
          <span class="value">
            info@bihdoc.com
          </span>
        </div>
        <div class="col-md-4">
          <span class="label">{{'CONTACTUS.label2'|translate}} </span>
          <span class="value">
            34 Central Street Al-Mukattam City, Cairo Governorate, Egypt 11571
          </span>
        </div>
      </div> -->
    <!-- <div class="row color">
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/Group 365.png">
        </div>
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/valu-logoo.png">
        </div>
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/layer1.png">
        </div>
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/NBE_logo.png">
        </div>
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/20170616022541!CIB_Logo (1).png">
        </div>
        <div class="col-md-2 col-4">
          <img src="assets/footer-img/1200px-Banque_Misr_Logo.svg.png">
        </div>
      
      </div>  -->
  </div>
</div>
