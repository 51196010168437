import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works-page',
  templateUrl: './how-it-works-page.component.html',
  styleUrls: ['./how-it-works-page.component.scss'],
})
export class HowItWorksPageComponent implements OnInit {
  panelOpenState = true;
  constructor() {}

  ngOnInit(): void {}
}
