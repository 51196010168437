import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export const API_contact_URL = environment.API_URL + 'contact-us';
@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {
  }

  sendEmail(data:any) {
    return this.http.post(API_contact_URL, data);
  }
  
}