import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surgeries',
  templateUrl: './surgeries.component.html',
  styleUrls: ['./surgeries.component.scss']
})
export class SurgeriesComponent implements OnInit {
  slideOptions = {
    nav: true,
    dots: false,
    rtl : true,
    margin: 50,

    responsiveClass: true,
    // responsive object contains responsive options.
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };
 

  constructor() { }

  ngOnInit(): void {
  }

}
