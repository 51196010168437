<div class="bg-color home">
  <div class="container">
    <div class="row">
      <div class="col-md-6 content" routerLink fragment="contact-us-container">
        <h3>{{'whatIs' | translate}}</h3>
        <p>{{'introduction' | translate}}</p>
        <a href="#contact-us-container" class="btn btn-color getstarted">{{'BookSurgery' | translate}}</a>
      </div>
      <!-- <div class="col-md-6">
          <img src="assets/images/Header-image.png" class="w-100 h-100">
      </div> -->
    </div>
  </div>
</div>

<app-how-it-works-page></app-how-it-works-page>
  <app-advantages></app-advantages>
<app-surgeries></app-surgeries>
<!-- <app-download></app-download> -->
<app-contact-us></app-contact-us>