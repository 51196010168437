import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from './services/contact.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  hasFormError: boolean =false;
  submited: boolean =false;
  address: string = '';
  constructor(private formBuilder: FormBuilder,
    private contactService: ContactService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')
      ]],
      phone: ['', [Validators.required]],
      body: ['', [Validators.required]]
    });
  }


  submit() {
    if (this.contactForm.invalid) {
      this.hasFormError = true;
      return;
    }
    this.hasFormError = false;
    this.submited = true;
    this.contactService.sendEmail(this.contactForm.value).subscribe(res => {
      this.initForm();
      const message = 'thank you for your submition will contact you soon'
      this.toastr.success(message);
      this.submited = false;
      // this.faceBookPixelsService.eventEmitter('track', 'Contact');
      // this.googleAnalyticsService.eventEmitter('contactUs', { email: this.contactForm.value.email });
    }, (error: HttpErrorResponse) => {
      console.log(error);
      this.submited = false;
      const message = this.showingErrors(error.error);
      const toastrConfig = {
        timeOut: 3000,
        positionClass: 'toast-bottom-center'
      };
      console.log(this.toastr.error(message));
      console.log(message);
    });
  }

   showingErrors(handler: any) {
    let messages = [];
    for (let errors in handler.errors) {
      for (let error of handler.errors[errors]) {
        messages.push(error);
      }
    }
    return messages.join('  ');
  }
}
