<div class="download" >
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title text-center">
          <span>{{'GetStarted' | translate}}</span>
          <h3>{{'Downloadnow' | translate}}</h3>
        </div>
      </div>
    </div>
    <div class="DisplayFlex">
      <div class="content text-center">
        <a href="https://play.google.com/store/apps/details?id=com.dgtlhub.beinhealth" target="_blank">
          <img src="assets/icons/GooglePlay.png" class="google">
          <p>
            {{'GooglePlay' | translate}}
            
           </p>
          <p class="des">
            {{'DownloadGooglePlay' | translate}}
           </p>
          <button class="btn btn-color">{{'Download' | translate}}</button>
        </a>
      </div>
      <div class="content text-center">
        <a href="https://apps.apple.com/qa/app/be-in-health-doctors/id1546616024" target="_blank">
          <img src="assets/icons/apple.png" class="apple">
          <p>
            {{'AppleStore' | translate}}
            
          </p>
          <p class="des">
            {{'DownloadAppleStore' | translate}}
           </p>
          <button class="btn btn-color">{{'Download' | translate}}</button>
        </a>
      </div>
    </div>

    <div class="terms-conditions">
      <ul class="list-unstyled my-4 d-flex flex-wrap align-items-center justify-content-center">
        <li class="px-4 py-2">
          <a routerLink="/doctors-privacy" class="text-capitalize">
            {{'doctorsTerms'|translate}}
          </a>
        </li>

        <li class="px-4 py-2">
          <a routerLink="/individuals-privacy" class="text-capitalize">
            {{'individulasTerms'|translate}}
          </a>
        </li>
      </ul>
    </div>

    <!-- <div class="contact-info" id="contactUs">
      <h4 class="mb-3">
        {{'CONTACTUS.ContactInfo'|translate}}
        :
      </h4>

      <ul class="list-unstyled">
        <li class="mb-2">
          <img style="width: 5%;" src="assets/icons/true.png">
          <span class="label">{{'CONTACTUS.label1'|translate}} </span>
          <span class="value mx-2">
            01011442277
          </span>
        </li>

        <li class="mb-2">
          <img style="width: 5%;" src="assets/icons/true.png">
          <span class="label">{{'CONTACTUS.label2'|translate}} </span>
          <span class="value mx-2">
            34 Central Street Al-Mukattam City, Cairo Governorate, Egypt 11571
          </span>
        </li>

        <li class="mb-2">
          <img style="width: 5%;" src="assets/icons/true.png">
          <span class="label">{{'CONTACTUS.label3'|translate}} </span>
          <span class="value mx-2">
            info@bihdoc.com
          </span>
        </li>
      </ul>
    </div> -->
  </div>
</div>
