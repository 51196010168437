import { Component, OnInit } from '@angular/core';
  import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss']
})
export class AdvantagesComponent implements OnInit {
 
  currentLanguage:any;
  constructor() { }
  // slideOptions:any = {
  //   items: 1,
  //   nav: false,
  //   dots: false,
  //   loop: true,
  //   rtl : true,
  //   margin: 20,
  //   lazyLoad : true,
  //   autoplay:true,
  //   responsiveClass: true,
  //   animateOut: 'fadeOut',

  //   // responsive object contains responsive options.
  //   responsive: {
  //     0: {
  //       items: 1,
  //        dots: false,
  //     },
  //     600: {
  //       items: 2,

  //     },
  //     1000: {
  //       items: 3,
  //     }
  //   }
  // };
  customOptions: OwlOptions = {
    loop: true,
    center: true,
    margin: 20,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    lazyLoad : true,
    autoplay:true,
    rtl : true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      }
    },
    nav: false
  }
  ngOnInit(): void {
   
  }
 

}
