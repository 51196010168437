<div class="nav-bar">
  <div class="bg-color">
  <div class="container">
  <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href=""><img src="assets/svg/logo.svg"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav" [class.mr-auto]="currentLanguage !== 'en'" [class.ml-auto]="currentLanguage == 'en'">
          <li class="nav-item active">
            <a class="nav-link" (click)="scrollfeature()">{{'Features' | translate}} <span class="sr-only">(current)</span></a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="#howItWork">{{'About' | translate}}</a>
          </li> -->
          <li class="nav-item" routerLink fragment="contact-us-container">
            <a class="nav-link" href="#contact-us-container">{{'ContactUs' | translate}}</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{'Language' | translate}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">

              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"
                  [checked]="currentLanguage === 'en'" (click)="changeLang('en')" [value]="'en'" checked />
                <label class="form-check-label" for="flexRadioDefault1"> {{'En' | translate}}</label>
              </div>

              <div class="form-group form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2"
                  [checked]="currentLanguage === 'ar'" (click)="changeLang('ar')" [value]="'ar'" />
                <label class="form-check-label" for="flexRadioDefault2"> {{'Ar' | translate}} </label>
              </div> -->

              <label class="check ">{{'En' | translate}}
                <input type="checkbox"  [checked]="currentLanguage === 'en'"
                (click)="changeLang('en')" [value]="'en'" checked>
                <span class="checkmark"></span>
              </label>
              <label class="check ">{{'Ar' | translate}}
                <input type="checkbox"[checked]="currentLanguage === 'ar'"
                (click)="changeLang('ar')" [value]="'ar'">
                <span class="checkmark"></span>
              </label>

              <!-- <div class="bg">
                <div>
                  <div class="chiller_cb">
                    <input id="myCheckbox" type="checkbox" [checked]="currentLanguage === 'en'"
                      (click)="changeLang('en')" [value]="'en'" checked>
                    <label for="myCheckbox">{{'En' | translate}} </label>
                    <span></span>
                  </div>
                  <div class="chiller_cb">
                    <input id="myCheckbox2" type="checkbox" [checked]="currentLanguage === 'ar'"
                      (click)="changeLang('ar')" [value]="'ar'">
                    <label for="myCheckbox2">{{'Ar' | translate}} </label>
                    <span></span>
                  </div>

                </div>
              </div> -->


            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link try" href="#">{{'TryitNow' | translate}}</a>
          </li> -->
        </ul>
      </div>
  </nav>
</div>
</div>
</div>
