import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentLanguage = 'en'
  Show_Language = false
  LanguagText;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('lang') || 'en';
    this.translate.get(this.currentLanguage).subscribe(res => this.LanguagText = res);
    $(document).ready(function () {
      $(document).on("click", function () {
          if($(window).height() > 300) {
        $(".navbar-toggler").addClass("collapsed");
        $(".navbar-collapse").removeClass("show");
          }
      });
    })

    $(window).on("scroll", function() {
      if($(window).scrollTop() > 50) {
          $(".bg-color").addClass("active");
      } else {
         $(".bg-color").removeClass("active");
      }
  });
  }


  changeLang(lang) {
    if (lang !== this.currentLanguage) {
      localStorage.setItem('lang', lang);
      location.reload();
    }
    this.Show_Language = false;
  }
  scrollfeature()
  {
    window.scrollTo(0, 1100);
  }
  scrollcontact()
{
  window.scrollTo(0, 2500);

}
}
