import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HeaderComponent } from './shared/components/header/header.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ApiLoaderComponent } from './shared/components/api-loader/api-loader.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AdvantagesComponent } from './pages/home-page/components/advantages/advantages.component';
import { HowItWorksPageComponent } from './pages/home-page/components/how-it-works-page/how-it-works-page.component';
import { SurgeriesComponent } from './pages/home-page/components/surgeries/surgeries.component';
import { OwlModule } from 'ngx-owl-carousel';
import { DownloadComponent } from './pages/home-page/components/download/download.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PrivacyPolicyIndividualsComponent } from './pages/privacy-policy/privacy-policy-individuals/privacy-policy-individuals.component';
import { PrivacyPolicyDoctorsComponent } from './pages/privacy-policy/privacy-policy-doctors/privacy-policy-doctors.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';






@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    HowItWorksPageComponent,
    AdvantagesComponent,
    SurgeriesComponent,
    DownloadComponent,
    PrivacyPolicyIndividualsComponent,
    PrivacyPolicyDoctorsComponent,
    ContactUsComponent,
    ApiLoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    OwlModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
